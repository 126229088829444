<template>
  <div>
    <div style="height: 30px;margin: 25px 0;display: flex;align-items: center">
      <el-breadcrumb class="bread" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breaditem" style="margin-right: 0" :to="{path: '/seller'}">
          <span style="color: #909399;font-weight: normal;">供货商</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item class="breaditem" style="margin-left: 0">
          <span style="color: #909399">账本：{{name}}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="selectdiv">
      <div style="width: 200px">
        <span style="height: 32px;line-height: 32px;font-size: 16px;color: #606266">余额</span>
        <span :style="{height: '32px',lineHeight: '32px',fontSize: '16px',marginLeft: '10px',
        color:balance===0?'#606266':(balance>0?'#67C23A':'#F56C6C')}">
        {{parseFloat(balance).toFixed(2)}}</span>
      </div>
      <div style="width: 800px;display: flex;justify-content: flex-end">
        <el-button :disabled="!alipay && !wechat" :plain="(!alipay && !wechat)"
                   :type="(!alipay && !wechat)?'info':'warning'"
                   size="small" icon="el-icon-picture-outline" @click="openCode">付款码
        </el-button>
        <el-button type="success" size="small" @click="openAdd" icon="el-icon-plus">新增账目
        </el-button>
      </div>
    </div>
    <div style="height: 70px">
      <div style="height: 47px"/>
      <div v-if="total>0" style="height: 23px;line-height: 13px">
        <span class="span" style="color: #606266">搜索出 </span>
        <span class="span" style="color: #409EFF">{{total}}</span>
        <span class="span" style="color: #606266"> 条结果</span>
      </div>
    </div>
    <el-table border stripe size="small" :data="accounts" :row-style="{height:'42px'}"
              :header-cell-style="{background:'#ECF5FF',fontWeight: 'normal'}"
              :style="{width: '701px'}" :cell-style="cellStyle">
      <el-table-column label="记账时间" prop="time" width="130" :resizable="false"
                       :formatter="timeFormat"/>
      <el-table-column label="收支金额" prop="cost" width="85" :resizable="false"
                       :formatter="costFormat"/>
      <el-table-column width="400" :resizable="false">
        <template slot="header">
          <div style="float: left">
            备注
            <el-tooltip placement="top-start" :enterable="false" effect="light">
              <div slot="content">
                手动新增的账目，点击可修改备注<br><br>
                自动新增的账目，点击可查看明细
              </div>
              <i class="el-icon-info" style="color: #C0C4CC"/>
            </el-tooltip>
          </div>
        </template>
        <template slot-scope="scope">
          <el-link v-if="scope.row.type!==0" class="link" :underline="false" @click="getDetail(scope.row)">
            {{scope.row.comment}}
          </el-link>
          <el-popover v-if="scope.row.type===0" placement="right" v-model="scope.row.visibleComment">
            <div style="display: flex;justify-content: center;align-items: center">
              <el-input class="family1" v-model.trim="comment" size="mini" maxlength="60" clearable
                        style="width: 300px !important" @keyup.enter.native="updComment(scope.row)" placeholder="备注"/>
              <el-button class="buttonPopo" size="mini" type="success" @click="updComment(scope.row)"
                         icon="el-icon-check"/>
            </div>
            <el-link class="link" slot="reference" :underline="false" @click="openComment(scope.row)"
                     :style="{color:scope.row.comment===''?'#C0C4CC':'#606266'}">
              {{scope.row.comment===''?'设置':scope.row.comment}}
            </el-link>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="余额" prop="balance" width="85" :resizable="false"
                       :formatter="balanceFormat"/>
    </el-table>
    <el-pagination background layout="prev,pager,next" :page-size="50" :total="total"
                   :current-page="query.page" @current-change="pageChange"
                   :hide-on-single-page="true"/>
    <el-dialog :visible.sync="visibleCode" custom-class="dialogCode" append-to-body
               :close-on-click-modal="true">
      <div style="display: flex;flex-direction: column;width: 470px">
        <div style="display: flex;justify-content: center;height: 41px;background-color: #ECF5FF">
          <span style="font-size: 13px;line-height: 23px;margin-top: 9px;color: #909399">{{name}}</span>
        </div>
        <div style="display: flex;justify-content: center;height: 360px">
          <el-image v-if="alipayUrl!==''" style="width: 235px; height: 360px" :src="alipayUrl" fit="cover"/>
          <el-image v-if="wechatUrl!==''" style="width: 235px; height: 360px" :src="wechatUrl" fit="cover"/>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="visibleAdd" custom-class="dialogAdd" append-to-body
               :close-on-click-modal="false">
      <el-form ref="addRef" :model="addModel" :rules="addRules" @submit.native.prevent
               style="width: 250px;height: 150px;margin: 50px 50px">

        <el-form-item prop="cost" style="margin-bottom: 15px">
          <el-input-number class="family" size="small" v-model="addModel.cost"
                           style="width: 250px" placeholder="收支金额"
                           :controls="false" :precision="2" :min="-99999.99" :max="99999.99"/>
        </el-form-item>

        <el-form-item prop="comment" style="margin-bottom: 15px">
          <el-input class="family" size="small" v-model.trim="addModel.comment"
                    style="width: 250px" placeholder="备注" maxlength="60"/>
        </el-form-item>

        <div style="display: flex;justify-content: space-between;height: 40px">
          <el-form-item>
            <el-button type="success" @click="addAccount" icon="el-icon-check"
                       :loading="loadingAdd" size="small">新增
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button v-if="!loadingAdd" size="small" @click="closeAdd">取消</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    created () {
      this.query.sellerId = sessionStorage.getItem('id')
      this.name = sessionStorage.getItem('name')
      this.alipay = JSON.parse(sessionStorage.getItem('alipay'))
      this.wechat = JSON.parse(sessionStorage.getItem('wechat'))
      this.getAccountList()
    },
    data () {
      return {
        query: {
          sellerId: null,
          page: 1
        },
        name: '',
        balance: 0,
        accounts: [],
        total: 0,
        comment: '',

        visibleCode: false,
        alipay: false,
        wechat: false,
        alipayUrl: '',
        wechatUrl: '',

        loadingAdd: false,
        visibleAdd: false,
        addModel: {
          cost: undefined,
          comment: ''
        },
        addRules: {
          cost:
            [
              {
                required: true,
                message: '请输入收支金额',
                trigger: 'blur'
              }
            ]
        }
      }
    },
    methods: {
      timeFormat (row) {
        const date = new Date(row.time)
        const y = date.getFullYear()
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let m = date.getMinutes()
        m = m < 10 ? ('0' + m) : m
        return y + '-' + MM + '-' + d + '　' + h + ':' + m
      },
      costFormat (row) {
        if (row.cost < 0) {
          return '－' + parseFloat(Math.abs(row.cost)).toFixed(2)
        } else if (row.cost > 0) {
          return '＋' + parseFloat(row.cost).toFixed(2)
        }
      },
      balanceFormat (row) {
        return parseFloat(row.balance).toFixed(2)
      },
      cellStyle ({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 1) {
          if (row[column.property] < 0) {
            return 'color: #F56C6C'
          } else if (row[column.property] > 0) {
            return 'color: #67C23A'
          }
        }
      },

      openComment (row) {
        this.comment = row.comment
      },
      async updComment (row) {
        this.$set(row, 'visibleComment', false)
        try {
          const { data: res } = await this.$http.get('updAccount',
            {
              params: {
                id: row.id,
                comment: this.comment
              }
            })

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
            return
          }
          row.comment = this.comment
          this.$msg.success('修改成功！')
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },
      getDetail (row) {
        if (row.orderbackId === undefined) {
          return
        }
        sessionStorage.setItem('orderbackId', row.orderbackId)
        if (row.type === 1) {
          this.getOrder(row.orderbackId)
        } else if (row.type === 2) {
          this.getBack(row.orderbackId)
        }
      },
      async getOrder (id) {
        try {
          const { data: res } = await this.$http.get('getOrderFlag', { params: { id: id } })
          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
            return
          }
          const route = this.$router.resolve({ name: (res.data ? 'complateorder' : 'order') })
          window.open(route.href, '_blank')
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },
      async getBack (id) {
        try {
          const { data: res } = await this.$http.get('getBackFlag', { params: { id: id } })
          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
            return
          }
          const route = this.$router.resolve({ name: (res.data ? 'complateback' : 'back') })
          window.open(route.href, '_blank')
        } catch (e) {
          this.$msg.error(this.netError)
        }
      },

      openCode () {
        this.alipayUrl = ''
        this.wechatUrl = ''
        if (this.alipay) {
          this.alipayUrl = this.codeURL + 'alipay_' + this.query.sellerId + '.jpg?' + new Date()
        }
        if (this.wechat) {
          this.wechatUrl = this.codeURL + 'wechat_' + this.query.sellerId + '.jpg?' + new Date()
        }
        this.visibleCode = true
      },
      openAdd () {
        this.visibleAdd = true
      },
      closeAdd () {
        this.addModel.cost = undefined
        this.addModel.comment = ''
        this.visibleAdd = false
      },
      async addAccount () {
        if (this.query.sellerId === null) {
          return
        }

        this.$refs.addRef.validate(async valid => {
          if (!valid) return

          if (this.addModel.cost === 0) {
            this.$msg.warning('收支金额不能为 0.00')
            return
          }

          try {
            this.loadingAdd = true

            const { data: res } = await this.$http.get('addAccount',
              {
                params: {
                  sellerId: this.query.sellerId,
                  cost: this.addModel.cost,
                  comment: this.addModel.comment
                }
              })

            this.loadingAdd = false

            if (!this.isLogin(res)) {
              return
            }
            if (res.code !== 0) {
              this.$msg.warning(res.msg)
              return
            }

            this.$msg.success('新增成功！')
            this.closeAdd()
            this.selectAccount()
          } catch (e) {
            this.loadingAdd = false
            this.$msg.error(this.netError)
          }
        })
      },

      selectAccount () {
        this.query.page = 1
        this.getAccountList()
      },
      pageChange (page) {
        this.query.page = page
        this.getAccountList()
      },
      async getAccountList () {
        if (this.query.sellerId === null) {
          return
        }

        const loading = this.loading()
        try {
          const { data: res } = await this.$http.get('getSellerAccountList', { params: this.query })

          if (!this.isLogin(res)) {
            loading.close()
            return
          }
          if (res.code !== 0) {
            this.balance = 0
            this.total = 0
            this.accounts = []
            loading.close()
            this.$msg.warning(res.msg)
            return
          }

          this.balance = res.data.count
          this.total = res.data.total
          this.accounts = res.data.data
          this.accounts = this.accounts.map(item => {
            this.$set(item, 'visibleComment', false)
            return item
          })

          loading.close()
          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          this.balance = 0
          this.total = 0
          this.accounts = []
          loading.close()
          this.$msg.error(this.netError)
        }
      }
    }
  }
</script>

<style scoped>
  /deep/ .family input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .family1 input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  /deep/ .el-button {
    height: 32px !important;
    font-size: 13px;
    padding: 0 15px;
  }

  .scope {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/ .buttonTable {
    width: 40px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 10px;
  }

  .span {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  .link {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  .spanPopo {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .buttonPopo {
    width: 40px !important;
    height: 28px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 0 0 10px;
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }

  /deep/ .el-dialog__header {
    display: none;
  }

  /deep/ .dialogAdd {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: 350px;
    height: 250px;
  }

  /deep/ .dialogCode {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    width: 470px;
    height: 401px;
  }

  /deep/ .el-input-number .el-input__inner {
    text-align: left;
  }

  /deep/ .el-table td,
  /deep/ .el-table th.is-leaf {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border th,
  /deep/ .el-table--border th.gutter:last-of-type {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border td,
  /deep/ .el-table--border th {
    border-right: 0.5px solid #EBEEF5 !important;
  }
</style>
